/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image, { FixedObject } from 'gatsby-image';

interface PureBioProps {
  data: {
    site: {
      siteMetadata: {
        author: {
          name: string;
          summary: string;
        };
      };
    };
    avatar: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
  };
}

export const PureBio = ({ data }: PureBioProps): ReactElement => {
  const { author } = data.site.siteMetadata;
  return (
    <div className="flex mb-20">
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          minWidth: 50,
        }}
        className="mr-4 mb-0"
        imgStyle={{
          borderRadius: '50%',
        }}
      />
      <p>
        Written by <strong>{author.name}</strong> {author.summary}
      </p>
    </div>
  );
};

const Bio = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  return <PureBio data={data} />;
};

export default Bio;
